<div class="search-box me-2 mb-2 d-inline-block w-100">
  <div class="position-relative">
    <input
      type="text"
      class="form-control"
      [placeholder]="'Search...' | translate"
      [formControl]="keyword"
      (input)="save()"
    />
    <i class="bx bx-search-alt search-icon"></i>
  </div>
</div>

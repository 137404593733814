import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Home",
    isTitle: true,
    role: "admin",
    permission: "view_dashboard_main_page",
  },
  {
    id: 2,
    label: "Home",
    icon: "bx-home-circle",
    link: "/dashboard",
    role: "admin",
    permission: "view_dashboard_main_page",

    // subItems: [
    //   {
    //     id: 3,
    //     label: "MENUITEMS.DASHBOARDS.LIST.DEFAULT",
    //     link: "/home",
    //     parentId: 2,
    //   },
    //   {
    //     id: 4,
    //     label: "System Users",
    //     parentId: 2,
    //     subItems: [
    //       {
    //         id: 7,
    //         label: "List Users",
    //         link: "/users/list",
    //         parentId: 4,
    //       },
    //       {
    //         id: 8,
    //         label: "Add new User",
    //         link: "/users/create",
    //         parentId: 4,
    //       },
    //     ],
    //   },
    // ],
  },
  {
    id: 11,
    label: "Home",
    isTitle: true,
    role: "company",
  },
  {
    id: 12,
    label: "Home",
    icon: "bx-home-circle",
    link: "/company-dashboard",
    role: "company",
  },
  {
    id: 89,
    label: "Management",
    isTitle: true,
    role: "admin",
    permission: "view_clients",
  },

  {
    id: 89,
    label: "Management",
    link: "/management",
    icon: "bx bx-group",
    role: "admin",
    permission: "view_users",
  },

  {
    id: 78,
    label: "Roles",
    isTitle: true,
    role: "admin",
    permission: "view_privileges",
  },

  {
    id: 78,
    label: "Roles",
    link: "/roles",
    icon: "bx bx-lock-alt",
    role: "admin",
    permission: "view_privileges",
  },

  {
    id: 232,
    label: "Display Subscriptions",
    isTitle: true,
    role: "admin",
    permission: "list_users_packages",
  },
  {
    id: 232,
    label: "Display Subscriptions",
    link: "/display-subscriptions",
    icon: "bx bx-user-voice",
    role: "admin",
    permission: "list_users_packages",
  },
  {
    id: 233,
    label: "Display Family Subscriptions",
    link: "/display-subscriptions/family",
    icon: "bx bx-group",
    role: "admin",
    permission: "list_users_packages",
  },
  {
    id: 89,
    label: "Users",
    isTitle: true,
    role: "admin",
    permission: "view_clients",
  },

  {
    id: 89,
    label: "Users",
    link: "/users",
    icon: "bx bx-group",
    role: "admin",
    permission: "view_clients",
  },
  {
    id: 89,
    label: "Affiliate",
    link: "/affiliate",
    icon: "bx bx-user-voice",
    role: "admin",
    permission: "view_affiliates",
  },
  {
    id: 89,
    label: "Companies",
    link: "/companies",
    icon: "bx bxs-bank",
    role: "admin",
    permission: "view_companies",
  },

  {
    id: 17,
    label: "Support",
    isTitle: true,
    role: "admin",
    permission: "view_support_messages_templates",
  },

  {
    id: 18,
    label: "Support",
    link: "/support",
    queryparams: { currentTap: 1 },
    icon: "bx-support",
    role: "admin",
    permission: "view_support_messages_templates",
  },

  {
    id: 67,
    label: "Join Request",
    isTitle: true,
    role: "admin",
    permission: "view_join_requests",
  },

  {
    id: 67,
    label: "Join Request",
    link: "/joinrequests",
    icon: "bx bxs-bank",
    role: "admin",
    permission: "view_join_requests",
  },
  {
    id: 106,
    label: "Reports",
    isTitle: true,
    role: "admin",
    permission: "view_reports",
  },
  {
    id: 151,
    label: "Reports",
    role: "admin",
    permission: "view_reports",

    subItems: [
      {
        id: 105,
        label: "Users Report",
        link: "/reports/usersReport",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 106,
        label: "Daily Report",
        link: "/reports/dailyoperation",
        icon: "bx bxs-report",
        queryparams: { today: 1 },
        parentId: 151,
      },
      {
        id: 107,
        label: "Monthly Report",
        link: "/reports/monthlyoperation",
        icon: "bx bxs-report",
        queryparams: { month: 1 },
        parentId: 151,
      },
      {
        id: 108,
        label: "Operation Reports",
        link: "/reports/operationReports",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 109,
        label: "Offers Reports",
        link: "/reports/genericReports",
        icon: "bx bxs-report",
        parentId: 151,
      },

      {
        id: 112,
        label: "Claimed Offer Reports",
        link: "/reports/claimedoffer",
        icon: "bx bxs-report",
        parentId: 151,
      },

      {
        id: 112,
        label: "Stores Report",
        link: "/reports/storereport",
        icon: "bx bxs-report",
        parentId: 151,
      },

      {
        id: 155,
        label: "Companies Report",
        link: "/reports/companies",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 113,
        label: "Online Stores",
        link: "/reports/onlineStoresReport",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 114,
        label: "Stores Location",
        link: "/reports/storesLocationReport",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 200,
        label: "Deals Report",
        link: "/reports/deals",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 201,
        label: "Coupons Usage Report",
        link: "/reports/couponsUsage",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 202,
        label: "Gifts Report",
        link: "/reports/gifts",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 203,
        label: "Packages Amounts Report",
        link: "/reports/packagesAmounts",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 204,
        label: "Deals Amounts Report",
        link: "/reports/dealsAmounts",
        icon: "bx bxs-report",
        parentId: 151,
      },
    ],
  },
  {
    id: 29,
    label: "Stores",
    isTitle: true,
    role: "admin",
    permission: "view_stores",
  },
  {
    id: 30,
    label: "Stores",
    icon: "bx-store-alt",
    link: "/stores",
    role: "admin",
    permission: "view_stores",
  },

  {
    id: 66,
    label: "Offers",
    isTitle: true,
    role: "admin",
    permission: "view_offers",
  },

  {
    id: 66,
    label: "Offers",
    link: "/offers",
    icon: "bx bxs-offer",
    role: "admin",
    permission: "view_offers",
  },

  {
    id: 66,
    label: "Expired Offers",
    link: "/offers/expired",
    icon: "bx bxs-offer",
    role: "admin",
    permission: "view_offers",
  },

  {
    id: 67,
    label: "Deals",
    link: "/deals",
    icon: "bx bx-bar-chart-square",
    role: "admin",
    permission: "view_offers",
  },
  {
    id: 78,
    label: "Voucher",
    isTitle: true,
    role: "admin",
    permission: "view_vouchers",
  },

  {
    id: 78,
    label: "Voucher",
    link: "/vouchers",
    icon: "bx bx-gift",
    role: "admin",
    permission: "view_vouchers",
  },
  {
    id: 88,
    label: "Packages",
    isTitle: true,
    role: "admin",
    permission: "view_packages",
  },

  {
    id: 88,
    label: "Packages",
    link: "/packages",
    icon: "bx bx-package",
    role: "admin",
    permission: "view_packages",
  },

  {
    id: 77,
    label: "Coupons",
    isTitle: true,
    role: "admin",
    permission: "view_coupons",
  },

  {
    id: 77,
    label: "All Coupons",
    link: "/coupons",
    icon: "bx bxs-discount",
    role: "admin",
    permission: "view_coupons",
  },

  {
    id: 88,
    label: "Offer Coupons",
    link: "/coupons",
    queryparams: {
      coupon_type: "offer",
    },
    icon: "bx bxs-discount",
    role: "admin",
    permission: "view_coupons",
  },

  {
    id: 99,
    label: "Package Coupons",
    link: "/coupons",
    queryparams: {
      coupon_type: "package",
    },
    icon: "bx bxs-discount",
    role: "admin",
    permission: "view_coupons",
  },
  {
    id: 77,
    label: "Generate Coupons",
    link: "/generate-coupons",
    icon: "bx bxs-discount",
    role: "admin",
    permission: "view_promo_codes",
  },

  {
    id: 20,
    label: "Categories",
    isTitle: true,
    role: "admin",
    permission: "view_categories",
  },

  {
    id: 21,
    label: "Categories",
    link: "/categories",
    icon: "bx-book-open",
    role: "admin",
    permission: "view_categories",
  },

  {
    id: 101,
    label: "Reservations",
    isTitle: true,
    role: "admin",
    permission: "view_reservations",
  },

  {
    id: 101,
    label: "Reservations",
    link: "/reservations",
    icon: "bx bx-cart",
    role: "admin",
    permission: "view_reservations",
  },
  {
    id: 111,
    label: "Like Card",
    isTitle: true,
    role: "admin",
    permission: "view_reservations",
  },

  {
    id: 112,
    label: "Like Card Categories",
    link: "/like-categories",
    icon: "bx-book-open",
    role: "admin",
    permission: "view_like_card_categories",
  },
  {
    id: 113,
    label: "Like Card Products",
    link: "/like-products",
    icon: "bx bx-package",
    role: "admin",
    permission: "view_like_card_products",
  },

  {
    id: 114,
    label: "Like Card Orders",
    link: "/reports/like-cart",
    icon: "bx bxs-report",
    role: "admin",
    permission: "view_like_card_orders",
  },

  {
    id: 65,
    label: "Payments",
    isTitle: true,
    role: "admin",
    permission: "view_payment_settings",
  },

  {
    id: 65,
    label: "Payments",
    link: "/payments",
    icon: "bx bxl-visa",
    role: "admin",
    permission: "view_payment_settings",
  },

  {
    id: 15,
    label: "Pages",
    isTitle: true,
    role: "admin",
    permission: "view_pages",
  },

  {
    id: 7,
    label: "Pages",
    link: "/pages/list",
    icon: "bx-spreadsheet",
    role: "admin",
    permission: "view_pages",
  },
  {
    id: 8,
    label: "New Page",
    link: "/pages/create",
    icon: "bx-list-plus",
    role: "admin",
    permission: "view_pages",
  },

  {
    id: 66,
    label: "Mobile Home",
    isTitle: true,
    role: "admin",
    permission: "edit_form_builder",
  },

  {
    id: 66,
    label: "Mobile Home",
    link: "/homeFilter",
    icon: "bx bx-filter-alt",
    role: "admin",
    permission: "edit_form_builder",
  },

  {
    id: 90,
    label: "App Intro Images",
    isTitle: true,
    role: "admin",
    permission: "view_sliders",
  },

  {
    id: 90,
    label: "App Intro Images",
    link: "/intro-splash",
    icon: "bx bx bx-images",
    role: "admin",
    permission: "view_sliders",
  },
  {
    id: 99,
    label: "mobileHomeImages",
    link: "/mobilehome",
    icon: "bx bx bx-images",
    role: "admin",
    permission: "view_sliders",
  },

  {
    id: 31,
    label: "Announcment",
    isTitle: true,
    role: "admin",
    permission: "view_ads",
  },
  {
    id: 32,
    label: "Announcment",
    icon: "bx-volume-full",
    link: "/announcment",
    role: "admin",
    permission: "view_ads",
  },

  {
    id: 201,
    label: "Notification",
    isTitle: true,
    role: "admin",
    permission: "view_notifications",
  },
  {
    id: 201,
    label: "Notification",
    icon: "bx bx-bell",
    link: "/notification",
    role: "admin",
    permission: "view_notifications",
  },

  {
    id: 11,
    label: "Users",
    isTitle: true,
    role: "company",
  },
  {
    id: 12,
    label: "New User",
    icon: "bxs-user-plus",
    link: "/company-employees",
    role: "company",
  },
  {
    id: 11,
    label: "Voucher",
    isTitle: true,
    role: "company",
  },
  {
    id: 12,
    label: "Voucher",
    icon: "bx bx-gift",
    link: "/company-vouchers",
    role: "company",
  },
  {
    id: 11,
    label: "Home",
    isTitle: true,
    role: "affiliate",
  },
  {
    id: 12,
    label: "Home",
    icon: "bx-home-circle",
    link: "/affiliatedashboard",
    role: "affiliate",
  },

  // {
  //   id: 13,
  //   label: "Transactions",
  //   isTitle: true,
  //   role: "affiliate",
  // },

  // {
  //   id: 14,
  //   label: "All Transactions",
  //   link: "/allTransactions",
  //   icon: "bx-book-open",
  //   role: "affiliate",
  // },

  // {
  //   id: 16,
  //   label: "Banks",
  //   isTitle: true,
  //   role: "affiliate",
  // },

  // {
  //   id: 16,
  //   label: "Banks",
  //   link: "/bankAccounts",
  //   icon: "bx bxs-bank",
  //   role: "affiliate",
  // },

  {
    id: 3,
    label: "Home",
    isTitle: true,
    role: "store",
  },
  {
    id: 3,
    label: "Home",
    icon: "bx-home-circle",
    link: "/storedashboard",
    role: "store",
  },
  {
    id: 3,
    label: "Reservations",
    isTitle: true,
    role: "store",
  },
  {
    id: 3,
    label: "Reservations",
    icon: "bx bx-cart",
    link: "/storeReservations",
    role: "store",
  },
  {
    id: 3,
    label: "Offers",
    isTitle: true,
    role: "store",
  },
  {
    id: 3,
    label: "Offers",
    icon: "bx bxs-offer",
    link: "/storeoffers",

    role: "store",
  },
  {
    id: 3,
    label: "Voucher",
    isTitle: true,
    role: "store",
  },
  {
    id: 3,
    label: "Voucher",
    icon: "bx bxs-discount",
    link: "/storeVouchers",
    role: "store",
  },
  {
    id: 3,
    label: "Reports",
    isTitle: true,
    role: "store",
  },
  {
    id: 3,
    label: "Operation Reports",
    icon: "bx bx-book-open",
    link: "/storeOperationReports",
    role: "store",
  },

  {
    id: 7,
    label: "Offers Terms And Conditions",
    isTitle: true,
    role: "admin",
    permission: "edit_terms_and_conditions",
  },

  {
    id: 7,
    label: "Offers Terms And Conditions",
    link: "/offerterms",
    icon: "bx-shield-alt-2",
    role: "admin",
    permission: "edit_terms_and_conditions",
  },
  {
    id: 9,
    label: "Change Phone Request",
    isTitle: true,
    role: "admin",
    permission: "view_change_phone_requests",
  },

  {
    id: 9,
    label: "Change Phone Request",
    link: "/changephone",
    icon: "bx bx-phone",
    role: "admin",
    permission: "view_change_phone_requests",
  },

  {
    id: 20,
    label: "Regions",
    isTitle: true,
    role: "admin",
    permission: "view_regions",
  },

  {
    id: 21,
    label: "Regions",
    link: "/regions",
    icon: "bx-world",
    role: "admin",
    permission: "view_regions",
  },
  {
    id: 40,
    label: "Nationality",
    isTitle: true,
    role: "admin",
    permission: "view_regions",
  },

  {
    id: 41,
    label: "Nationality",
    link: "/nationality",
    icon: "bx bxs-plane-take-off",
    role: "admin",
    permission: "view_regions",
  },
  {
    id: 121,
    label: "Questions Section",
    isTitle: true,
    role: "admin",
    permission: "view_questions",
  },

  {
    id: 121,
    label: "Questions Section",
    link: "/questions",
    icon: "bx bx-question-mark",
    role: "admin",
    permission: "view_questions",
  },

  {
    id: 51,
    label: "Banks",
    isTitle: true,
    role: "admin",
    permission: "view_banks",
  },

  {
    id: 51,
    label: "Banks",
    link: "/bank",
    icon: "bx bxs-bank",
    role: "admin",
    permission: "view_banks",
  },

  {
    id: 22,
    label: "Services",
    isTitle: true,
    role: "admin",
    permission: "view_services",
  },
  {
    id: 23,
    label: "Services",
    icon: "bx-wrench",
    link: "/services",
    role: "admin",
    permission: "view_services",
  },

  {
    id: 5,
    label: "Settings",
    isTitle: true,
    role: "admin",
    permission: "view_settings",
  },
  {
    id: 6,
    label: "Settings",
    icon: "bx bxs-cog",
    link: "/settings",
    role: "admin",
    permission: "view_settings",
  },
];

import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(items: any[], searchText: string): any[] {
    // return empty array if array is falsy
    if (!items) {
      return [];
    }
    // return the original array if search text is empty
    if (!searchText) {
      return items;
    }
    // convert the searchText to lower case
    searchText = searchText.toLowerCase();

    // retrun the filtered array based on lang
    return items.filter((item) => {
      if (item && item["label"] && !item["isTitle"]) {
        return (
          this.translate.instant(item["label"]).includes(searchText) ||
          item["label"].toLowerCase().includes(searchText)
        );
      }
      return false;
    });
  }
}

import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CitySearchComponent } from "./city-search/city-search.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { PagetitleComponent } from "./pagetitle/pagetitle.component";
import { LoaderComponent } from "./loader/loader.component";
import { CountrySearchComponent } from "./country-search/country-search.component";
@NgModule({
  declarations: [
    PagetitleComponent,
    LoaderComponent,
    CitySearchComponent,
    CountrySearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    PagetitleComponent,
    LoaderComponent,
    CitySearchComponent,
    CountrySearchComponent,
  ],
})
export class UIModule {}

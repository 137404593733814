import { RegionsService } from "./../../../core/services/regions/regions.service";
import { ICountryData } from "./../../../core/models/regions.models";
import { FormControl } from "@angular/forms";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "[app-country-search]",
  templateUrl: "./country-search.component.html",
  styleUrls: ["./country-search.component.scss"],
})
export class CountrySearchComponent implements OnInit {
  keyword: FormControl;
  @Output() emptyList = new EventEmitter();
  @Input() name;
  @Output() onSearch = new EventEmitter();

  filterCountryOptions: ICountryData = {
    page: 1,
    return_all: 0,
  };

  constructor(private regionsService: RegionsService) {}

  // convenience getter for easy access to form fields

  ngOnInit(): void {
    this.createForm();
  }
  createForm(): void {
    this.keyword = new FormControl("");
  }
  save() {
    // if (this.keyword.value) {
    this.filterCountryOptions.keyword = this.keyword.value;
    this.regionsService.getAllCountries(this.filterCountryOptions).subscribe(
      (res) => {
        if (res) {
          this.onSearch.emit(res.data.data);
          this.emptyList.emit(false);

          if (!res.data.data.length) {
            this.emptyList.emit(true);
            // setTimeout(() => {
            //   this.emptyList = false
            //   this.keyword.reset()
            // }, 1000);
          }
        }
      },
      (err) => {
        this.onSearch.emit("false");
      }
    );
    // } else {
    //   this.onSearch.emit('false')
    // }
  }
}

import { urlSettings } from "./../app/http-confing/settings/urlSettings";
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBXVz8xW7_vcoO18wfohMtQcK8oxT6hXLI",
    authDomain: "usoomat-f2825.firebaseapp.com",
    databaseURL: "https://usoomat-f2825.firebaseio.com",
    projectId: "usoomat-f2825",
    storageBucket: "usoomat-f2825.appspot.com",
    messagingSenderId: "830789041249",
    appId: "1:830789041249:web:c8265fa82a52339ecc7e20",
    measurementId: "G-GVP3NC67PB",
  },
};

urlSettings.Settings = {
  apiProtocol: "https",
  apiHost: "oula.firstcard.sa",
  apiEndPoint: "api",
  language: "ar",

  // apiProtocol: "https",

  // apiHost: "624a-197-58-35-156.ngrok-free.app",
  // apiEndPoint: "api",
  // language: "ar",

  // apiPort: 7000,
  // apiProtocol: "http",
  // apiHost: "192.168.0.35",
  // apiEndPoint: "api",
  // language: "ar",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
